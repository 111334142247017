<template>
    <div class="amap-page-container">
      <el-amap
        ref="map"
        :center="center"
        :zoom="zoom"
        :plugin="plugin"
        class="amap-demo">
        <!-- 坐标点 -->
        <el-amap-marker vid="amapDemo" :position="center"></el-amap-marker>
      </el-amap>
    </div>
</template>
<script>
import Vue from 'vue'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)

export default {
  name: 'Gaode',
  props: ['mapList'],
  created () {
    this.initMap();
  },
  mounted () {
  },
  data() {
    return {
      zoom: 12,
      center: [], // 中心位置
      plugin: [],
    }
  },
  methods: {
    initMap(){
    this.center = JSON.parse(JSON.stringify(this.mapList))
      this.amapManager = new VueAMap.AMapManager()
      VueAMap.initAMapApiLoader({
      key: '你的开发者key', 
      plugin: [],
      uiVersion: '1.0.11' // 版本号
    })
    },
  }
}
</script>

<style scoped>
    .amap-demo {
      height:210px;
      border-radius:12px;
    }
    .search-box {
      position: absolute;
      top: 25px;
      left: 70px;
    }
    .amap-page-container {
      position: relative;
    }
    .toolbar button {
      background: #42b983;
      border: 0;
      color: white;
      padding: 8px;
      margin: 0 5px;
      cursor: pointer;
      margin-top:10px;
    }
</style>